import React, {useEffect, useState} from 'react';
import axios from "axios";
import endpoints from "../endpoints";
import {
    useParams
} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./Header";
import InstagramEmbed from 'react-instagram-embed';

const ReactMarkdown = require('react-markdown')

const Page = () => {
    const [data, setData] = useState(0);
    let { pageUrl } = useParams();

    useEffect(() => {
        async function fetchPageData () {
            try {
                const response = await axios.get(endpoints.page(pageUrl));
                const pageData = response.data[0];
                setData(pageData)

            } catch (e) {

            }
        }

        fetchPageData();
    }, [pageUrl])

    function prependUri(incomming) {
        let apiUri;
        if (process.env.NODE_ENV === 'development') {
            apiUri = process.env.REACT_APP_API_URI_DEVELOPMENT;
        } else {
            apiUri = process.env.REACT_APP_API_URI_PRODUCTION;
        }
        return `${apiUri}${incomming}`;
    }


    return (
        <div>
            <Header/>
            <div className="content-padding">
                <Container fluid={true}>
                    <Row className="justify-content-center">
                        <Col sm={6}>
                            <h1 className="mb-5">{data.title}</h1>

                            <ReactMarkdown transformImageUri={prependUri} source={data.page_content} />

                          {data.instagram && <div>
                            {/* Begin Mailchimp Signup Form */}
                            <link href="//cdn-images.mailchimp.com/embedcode/slim-10_7.css" rel="stylesheet" type="text/css" />
                            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }\n\t/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.\n\t   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n" }} />
                            <div id="mc_embed_signup">
                              <form action="https://mariereintjes.us14.list-manage.com/subscribe/post?u=54daf9b606f6243457b0a1782&id=fff99ab3a2" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate p-0 pt-5" target="_blank" noValidate>
                                <div id="mc_embed_signup_scroll">
                                  <label htmlFor="mce-EMAIL">Please enter your email address below to subscribe to my newsletter.</label>
                                  <input type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required />
                                  {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                                  <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_54daf9b606f6243457b0a1782_fff99ab3a2" tabIndex={-1} defaultValue /></div>
                                  <div className="clear"><input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
                                </div>
                              </form>
                            </div>
                            {/*End mc_embed_signup*/}
                          </div>}

                          <div className="d-flex flex-wrap mt-5">
                            {
                              console.log(data.instagram_posts)
                            }
                            {data.instagram && data.instagram_posts.length >
                            0 && data.instagram_posts.map(post => <InstagramEmbed
                                className="p-4"
                                key={post.id}
                                url={post.URL}
                                clientAccessToken={`${process.env.REACT_APP_INSTAGRAM_ID}|${process.env.REACT_APP_INSTAGRAM_TOKEN}`}
                                maxWidth={320}
                                hideCaption={false}
                                containerTagName='div'
                                protocol=''
                                injectScript
                                onLoading={() => {
                                }}
                                onSuccess={() => {
                                }}
                                onAfterRender={() => {
                                }}
                                onFailure={() => {
                                }}
                            />)

                            }
                          </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Page;