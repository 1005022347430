import React, {useState, useEffect} from 'react';
import {faBars, faTimes} from '@fortawesome/free-solid-svg-icons'

// Import Bootstrap elements
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import axios from "axios";
import endpoints from "../endpoints";

const Header = () => {
    const [menu, setMenu] = useState(false);

    function toggleMenu(){
        setMenu(!menu)
    }
    return (
        <div className="content-padding" id="header">
            <Container fluid={true}>
                <Row>
                    <Col>
                        <FontAwesomeIcon onClick={() => toggleMenu()} className="h1" icon={faBars}/>
                    </Col>
                    <Col>
                        <h1 className="text-right">Marie Reintjes</h1>
                    </Col>
                </Row>
            </Container>
            {/*Show menu when toggled to true*/}
            {menu &&
                <Menu setMenu={setMenu} toggleMenu={toggleMenu}/>
            }
        </div>
    );
};

const Menu = (props) => {
    const [menuItems, setMenuItems] = useState([]);
    useEffect(()=> {
        fetchPages();
    }, [])

    async function fetchPages () {
        try {
            const response = await axios.get(endpoints.pages);
            setMenuItems(response.data);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div id="main_menu">
            <div onClick={() => props.toggleMenu()} className="main_menu--close h1 d-flex align-items-center">
                <FontAwesomeIcon icon={faTimes}/> close
            </div>
            <nav>
                <ul>
                    <Link onClick={() => props.setMenu(false)} to={`/`}>Home</Link>
                    {menuItems.map((item) => {
                        return <li key={item.id}>
                            <Link onClick={() => props.setMenu(false)} to={`/${item.url}`}>{item.title}</Link>
                        </li>
                    })}
                </ul>
            </nav>
        </div>
    )
}

export default Header;