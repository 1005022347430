import React from 'react';
import Header from "./Header";
import ArtCollections from "./ArtCollections";
import ArtPieces from "./ArtPieces";
import { useParams } from "react-router";
import {BackToTop} from "./Utilities/Utilities";


function Home (props) {
  let { tagName } = useParams();
    return (
        <div id="app-wrapper">
            <BackToTop/>
            <Header />
            <ArtCollections tagName={tagName}/>
            <ArtPieces tagName={tagName}/>
        </div>
    );
};

export default Home;