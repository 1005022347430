import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {useDispatch, useSelector} from "react-redux";
import {selectedArtPiece, setSelected} from "../../features/art/artSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleUp} from "@fortawesome/free-solid-svg-icons";

export const CustomImage = (props) => {
    const [enlarge, setEnlarge] = useState(false);
    const isMobile = useMediaQuery({query: '(max-width: 1024px)'})
    let source;
    if (process.env.NODE_ENV === 'development') {
      source = process.env.REACT_APP_API_URI_DEVELOPMENT;
    } else {
      source = process.env.REACT_APP_API_URI_PRODUCTION;
    }
    const imgSrc = isMobile ? source + props.afbeelding.formats.medium.url : source + props.afbeelding.url;
    return (
        <div className="image">
            <div className="art-piece__image" onClick={() => setEnlarge(!enlarge)}>
                <img src={imgSrc} alt={props.afbeelding.alternativeText}/>
            </div>
            <div className={enlarge ? "image--selected" : "image--hidden"} onClick={() => setEnlarge(!enlarge)}>
                <div className="art-piece__image">
                    <img src={imgSrc} alt={props.afbeelding.alternativeText}/>
                </div>
            </div>
        </div>
    );
};

export const BackToTop = () => {
    const dispatch = useDispatch();
    const selected = useSelector(selectedArtPiece);
    return (
        <div>
            {selected > 0 &&
            <div className="backtotop" onClick={() => dispatch(setSelected([0, 0]))}>
                <FontAwesomeIcon className="h4 ml-3" icon={faArrowCircleUp}/>
            </div>
            }
        </div>
    );
};
