import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Home from "./Components/Home";
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/index.scss';
import Page from "./Components/Page";

require('dotenv').config();

function App() {
    return (
    <Router>
        <div>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
                <Route path="/tags/:tagName">
                    <Home />
                </Route>
                <Route path="/:pageUrl">
                    <Page/>
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </div>
    </Router>
    );
}

export default App;
