let apiUri;

if (process.env.NODE_ENV === 'development') {
    apiUri = process.env.REACT_APP_API_URI_DEVELOPMENT;
} else {
    apiUri = process.env.REACT_APP_API_URI_PRODUCTION;
}

export default {
    "artPieceTags":(pieceID) => `${apiUri}/art-pieces?id=${pieceID}`,
    "getPiecesFromTag": (tagName) => `${apiUri}/art-pieces?tags.name=${tagName}`,
    "artCollections": `${apiUri}/art-collections?_sort=name:DESC`,
    "translations": `${apiUri}/translation`,
    "pages": `${apiUri}/pages`,
    "page": (pageUrl) => `${apiUri}/pages?url=${pageUrl}`,
}