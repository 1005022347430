import {createSlice} from '@reduxjs/toolkit';
import axios from "axios";
import endpoints from "../../endpoints";

export const artSlice = createSlice({
    name: 'art',
    initialState: {
        selectedCollection: {}, // Default collection
        collections: [],
        artPieces: [],
        selected: 0,
        tags: [],
        previousSelected: 0,
        loading: {
            artPieces: false
        }
    },
    reducers: {
        setCollection: (state, action) => {
            console.log(action.payload);
            state.selectedCollection = action.payload;
        },
        setArt: (state, action) => {
            state.artPieces = action.payload;
        },
        setCollections: (state, action) => {
            state.collections = action.payload;
        },
        setSelected: (state, action) => {
            state.selected = action.payload[0];
            state.previousSelected = action.payload[1];
        },
        setLoading: (state, action) => {
            state.loading.artPieces = action.payload;
        },
        setTags: (state, action) => {
            state.tags = action.payload;
        },
    },
});

export const {setCollection,setCollections, setSelected, setLoading, setTags, setArt} = artSlice.actions;

export const selectArt = (index, original, length, id) => async dispatch => {
    // Check if index is within the array range
    if ((index < length) && (index >= 0)) {
        dispatch(setSelected([index, original]));
    }
}

export const fetchCollections = () => async dispatch => {
        try {
            dispatch(setLoading(true));
            const response = await axios.get(endpoints.artCollections);

            dispatch(setCollections(response.data));
            if (response.data.length > 0) {
                dispatch(setCollection(response.data[0]))
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
        }
};

export const fetchTagPieces = (tagName) => async dispatch => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get(endpoints.getPiecesFromTag(tagName));
        dispatch(setArt(response.data));

        dispatch(setLoading(false));
    } catch (e) {
        dispatch(setLoading(false));
    }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectLoading = state => state.art.loading.artPieces;
export const selectTags = state => state.art.tags;
export const selectArtPieces = state => state.art.artPieces;
export const selectCollection = state => state.art.selectedCollection;
export const selectCollections = state => state.art.collections;
export const selectedArtPiece = state => state.art.selected;
export const selectedPrevArtPiece = state => state.previousSelected;
export default artSlice.reducer;