import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {useDispatch, useSelector} from 'react-redux';
import 'moment-timezone';
import {
  selectArtPieces,
  selectArt,
  selectedArtPiece,
  selectedPrevArtPiece,
  fetchTagPieces,
  selectLoading, selectCollection, setSelected,
} from './../features/art/artSlice';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faDotCircle,
  faCircleNotch,
  faPaintBrush,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import {CustomImage} from './Utilities/Utilities';
import {Link} from 'react-router-dom';


const ArtPieces = (props) => {
  const dispatch = useDispatch();
  let amountPerPage = 5;
  let CurrentCollection = useSelector(selectCollection);
  const selected = useSelector(selectedArtPiece);
  const artPieces = useSelector(selectArtPieces);
  const previousSelected = useSelector(selectedPrevArtPiece);
  const loading = useSelector(selectLoading);
  const [pieces, setPieces] = useState([]);
  const [tag, setTag] = useState(false);
  const isMobile = useMediaQuery({query: '(max-width: 1024px)'})

  function mouseWheelMoved(e) {
    // Scrolling down
    if (e.deltaY > 0) {
      nextArtPiece();

    } else if (e.deltaY < 0) {
      //Scrolling up
      previousArtPiece();
    }
  }

  function nextArtPiece() {
      dispatch(selectArt(selected + 1, selected, pieces.length));
  }

  function previousArtPiece() {
    dispatch(selectArt(selected - 1, selected,
        pieces.length));
  }

  useEffect(() => {
    setTag(false);
    dispatch(setSelected([0, 0]));
  }, [CurrentCollection]);

  useEffect(() => {

    if (props.tagName !== undefined) {
      dispatch(fetchTagPieces(props.tagName));
    } else {

      if (CurrentCollection.list !== undefined && CurrentCollection.list.length > 0) {
        const pieces = []
        CurrentCollection.list.map((ordered) => {
          pieces.push(ordered.art_piece);
        });

        setPieces(pieces);
      }
    }
  }, [CurrentCollection, props.tagName, tag]);

  useEffect(() => {
    if (props.tagName !== undefined) {
      setPieces(artPieces);
    }
  }, [artPieces]);

  useEffect(() => {
    window.addEventListener('wheel', mouseWheelMoved);
    return function cleanup() {
      window.removeEventListener('wheel', mouseWheelMoved);
    };
  }, [pieces, selected, CurrentCollection]);

  return (
      <div className="content-padding">
        {!loading && pieces !== undefined && pieces.length > 0 &&
        <div>
          <div id="art--desktop">
            {
              pieces.map((item, index) => {
                if (item === null) return false;
                console.log(item);
                  if (index > selected + amountPerPage || index <
                      selected) return null;
                  let prevSelected = false;
                  if (index === previousSelected) prevSelected = true;

                  if (selected === index) return <ArtPiece {...item}
                                                           prevSelected={prevSelected}
                                                           key={item.id}
                                                           index={index}
                                                           alt="test"/>;
                  return <ArtPieceSmall {...item}  prevSelected={prevSelected}
                                        key={item.id} index={index}
                                        alt="test"/>;
              })
            }
            <div className="art-piece--small art-piece--final">
              <Row>
                <div className="d-none d-lg-flex art-piece__timeline">
                  <div className="timeline__index">

                  </div>
                  <div className="timeline__dots">
                    <div className="timeline__point"><FontAwesomeIcon
                        icon={faCircleNotch}/></div>
                  </div>
                </div>
                <Col sm={11}>
                  <div className="art-piece__details">
                    <p>Out of paint. <FontAwesomeIcon icon={faPaintBrush}/></p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div id="art--mobile">
            {
              pieces.map((item, index) => {
                if (item === null) return false;
                return <ArtPiece {...item} key={item.id}
                                 index={index} alt="test"/>
            })}
          </div>
        </div>
        }
        {loading &&
        <div>
          <div className="d-flex justify-content-center mt-5">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        }

        {!loading && pieces !== undefined && pieces.length  < 1 &&
        <div>
          <div className="d-flex justify-content-center mt-5 flex-column">
            <p className="text-center">This collection appears to be empty.</p>
            <br/>
            <h1 className="text-center h1"><FontAwesomeIcon icon={faEyeSlash}/>
            </h1>
          </div>
        </div>
        }
      </div>
  );
};

const ArtPiece = (props) => {
  const tags = props.tags.map(tag => {
    return <Link to={`/tags/${tag.name}`} key={tag.id} className='mb-2 mr-2' >
      <div className='py-2 px-3 tag-round text-dark bg-light'>
        {tag.name}
      </div>
    </Link>
  })
  return (
      <div className="art-piece">
        <Row>
          <div lg={1} className="d-none d-lg-flex art-piece__timeline">
            <div className="timeline__index">
              {props.index + 1}
            </div>
            <div className="timeline__dots">
              <div className="timeline__point"><FontAwesomeIcon
                  icon={faDotCircle}/></div>
            </div>
          </div>
          <Col md={12} lg={4}>
            <div className="art-piece__details">
              <h1>{props.Titel} </h1>
              <div className="art-piece__year py-lg-4">
                ({props.Jaartal})
              </div>
              <p>{props.Grootte} cm | {props.Beschrijving}</p>

              <div className='d-flex flex-wrap mb-4 mb-md-0'>
                {
                  tags
                }
              </div>
            </div>
          </Col>
          <Col className="position-static" md={12} lg={7}>
            <CustomImage afbeelding={props.Afbeelding}/>
          </Col>


        </Row>
      </div>
  );
};

const ArtPieceSmall = (props) => {
  return (
      <div className={props.prevSelected ?
          'prevSelected art-piece--small' :
          'art-piece--small'}>
        <Row>
          <div lg={1} className="d-none d-lg-flex art-piece__timeline">
            <div className="timeline__index">
              {props.index + 1}
            </div>
            <div className="timeline__dots">
              <div className="timeline__point"><FontAwesomeIcon
                  icon={faDotCircle}/></div>
            </div>
          </div>
          <Col md={12} lg={3}>
            <div className="art-piece__details">
              <p>{props.Titel} | {props.Grootte} cm | {props.Beschrijving}</p>
            </div>
          </Col>
        </Row>
      </div>
  );
};

export default ArtPieces;