import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    setCollection,
    fetchCollections,
    selectCollections
} from './../features/art/artSlice';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {faImages, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Redirect, useHistory } from 'react-router-dom';

const ArtCollections = (props) => {
    let collections = useSelector(selectCollections);
    const [loading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchCollections());
    }, []);

    useEffect(() => {
        if(collections.length > 0) {
            dispatch(setCollection(collections[0]))
        }
    }, [collections]);

    function collectionSelected(e) {
        e.preventDefault();
        dispatch(setCollection(collections[e.target.value]));
        history.push('/');
    }

    function selectFirstCollection(index) {
        dispatch(setCollection(collections[index]));
        history.push('/');
    }

    return (
        <div className="content-padding">
            <Container fluid={true}>
                <Row>
                    <Col>
                        <div className="content-padding collection-selection">
                            {!loading &&

                            <div className="d-flex">
                                {props.tagName === undefined &&
                                <div className="d-flex align-items-center">

                                    <select name="ding" id="" onChange={(e) => collectionSelected(e)}>
                                        {collections.map((item, index) => {
                                            return <option key={item.id}
                                                           value={index}>{item.Name}</option>;
                                        })}
                                    </select>
                                    <FontAwesomeIcon className="h4 ml-3" icon={faImages}/>

                                </div>
                                }
                                {props.tagName !== undefined &&
                                <h1 className='closeTag' onClick={() => selectFirstCollection(0)}>
                                    Close <FontAwesomeIcon className="h4 ml-3" icon={faTimes}/>
                                </h1>
                                }
                                {props.tagName !== undefined && <h1 className="ml-5 mb-0">Showing {props.tagName}</h1>}
                            </div>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    );
};

export default ArtCollections;